@import "../../../assets/scss/definitions";

.header {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 60px;
  padding: 10px 40px 10px 60px;

  justify-content: space-between;

  background: white;
  z-index: 999;

  -webkit-box-shadow: 0px 10px 10px 0px #B2B2B2;
  box-shadow: 0px 10px 10px 0px #B2B2B2;

  .logo {

    align-items: center;

    img {
      height: 100%;
      width: auto;
    }

    .logo-title {
      @include serifFonts;
      font-size: 24px;
      line-height: 24px;
      margin-left: 15px;
    }
  }

  nav.menu-nav {

    ul.menu {
      list-style: none;
      height: 100%;

      li {
        font-size: 22px;
        line-height: 22px;
        margin: 0 10px;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        a, button {
          display: flex;
          height: 100%;
          align-items: center;
          color: $mainBlack;

          &:hover, &.active {
            color: $mainGold;
          }
        }

        &.customer-number {
          flex-direction: column;
          font-size: 14px;
        }
      }
    }
  }
}
