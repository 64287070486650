@import "definitions";
@import "controls/index";
@import "~bootstrap/scss/bootstrap";
@import "main";
@import "theme";
@import "framework_imports";
@import "bootstrap-overwrites/index";
@import '~filepond/dist/filepond.min.css';
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

@import "~@thekeytechnology/framework-react-ux/dist/core/components/table/table";

body {
  height: 100vh;
  overflow: hidden;
}

a:hover {
  cursor: pointer;
}

a, .btn-link {
  border: none;
  background-color: transparent;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.btn-link {
  background: none;
  border: none;
  padding: 0;

}
