@import "../../../assets/scss/definitions";

.tk-live-auction {


}

.tk-auction-content-wrapper {

  .tk-auction-meta {

    display: flex;
    align-items: stretch;

    margin-bottom: 40px;

    .tk-image-wrapper {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;

      .image-gallery {
        max-width: 100%;
      }

      .image-gallery-lot-status-display {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        span {
          font-size: 24px;
          line-height: 1.2;
          border: 3px solid $mainRed;
          color: $mainRed;
          background: rgba(255,255,255,0.7);
          padding: 30px;
          font-weight: bold;
          max-width: 90%;
          white-space: normal;
        }
      }

      img {
        max-width: 100%;
        max-height: 400px;
        width: auto;
        height: auto;
      }
    }

    .tk-meta-content {
      flex: 1;

      display: flex;
      flex-direction: column;


      .tk-auction-ticket-nav {

        font-weight: bold;
        @include sansFonts;

        a {

          color: #b0b0b0;

          &.tk-prev {
            margin-right: 10px;
          }

          &.tk-next {
            margin-left: 10px;
          }
        }

        .lot-status {
          margin-left: 10px;

          &.live {
            color: $mainGold;
          }
        }
      }

      h1 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        @include serifFonts

      }

      .tk-auction-ticket-stats {
        color: $lightGrey;
      }
    }
  }

  .tk-auction-description {
    @include serifFonts;
  }
}

.tk-auction-bidding {

  @include sansFonts;

  > div:first-child {
    border-top: solid 1px #e2e2e2;
  }

  .last-call-banner {
    width: 100%;
    padding: 5px;
    background: $mainRed;
    color: white;
    text-align: center;
    margin-bottom: 10px;
  }

  .tk-bidding-row {

    border-bottom: solid 1px #e2e2e2;
    padding: 10px 0;
    color: $lightGrey;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tk-label {
      flex: 1;
    }

    .tk-value {
      font-size: 1.74em;
      @include serifFonts;
      font-weight: bold;
      flex: 1;
      text-align: right;
    }

    &.tk-submit-bid {
      input {
        font-size: 1em;
        @include serifFonts;
        font-weight: bold;
        text-align: right;
        width: 100%;
        padding: 0;
        background: transparent;
        border: none;
        border-bottom: solid 1px #e2e2e2;
      }
    }
  }

  .tel-check-row {
    display: flex;
    flex-direction: row-reverse;

    label {
      flex: 1;
      max-width: none;
    }

    .col-sm-10 {
      width: 40px;
      flex: 0;
      padding: 0;

      input {
        width: 20px;
      }
    }
  }

  .submit-bid-button {
    color: white;
    border: none;
    border-radius: 0;
    width: 100%;
    background-color: $mainGold;

    &:hover {
      background-color: lighten($mainGold, 10);
    }

    &.last-call {
      background-color: $mainRed;
      &:hover {
        background-color: lighten($mainRed, 10);
      }
    }

    &.highest-bidder {
      background-color: $lightGrey;
      color: $mainBlack;
    }
  }
}

.tk-live-auction-message-banner {
  width: calc(100% - 30px);
  margin-left: 30px;
  margin-bottom: 20px;
  padding: 10px 20px;
  text-align: center;
  font-size: 1.5em;
  background-color: $mainGold;
  color: white;
}

.tk-live-auction-sidebar {
  background: $veryLightGold;

  padding: 15px 0 0;

  .tk-auction-video-feed {
    padding: 0 15px;

    iframe {
      width: 100%;
      height: 300px;
    }
  }

  .tk-catalogue-item-filter {
    padding: 0 15px;

    .clear-filters {
      display: none !important;
    }
  }

  .tk-auction-item-list {
    max-height: 700px;
    overflow-y: scroll;
    .tk-auction-item-list-item {
      display: flex;
      padding: 15px;

      border-bottom: 1px solid #e2e2e2;

      &:hover {
        text-decoration: none;
        background-color: rgba(255, 255, 255, 0.5);
      }

      &:first-child {
        border-top: 1px solid #e2e2e2;
      }

      &.current-item {
        background-color: #FBFAF8;
        .tk-ticket-number, h4, .tk-ticket-stats {
          color: $mainGold;
        }
      }

      .tk-thumbnail {
        max-height: 80px;
        overflow: hidden;
        justify-content: center;
        display: flex;
        align-items: flex-start;

        img {
          width: 150px;
        }
      }

      .tk-ticket-number {
        font-weight: bold;
      }

      .tk-lot-status {
        font-weight: bold;
      }

      .tk-content, .tk-ticket-stats {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        line-height: 1;
      }

      .tk-content {
        color: $mainBlack;
      }

      .tk-ticket-stats {
        color: $lightGrey;
        text-align: right;
      }


      h4 {
        font-size: 1em;
        font-weight: normal;
        margin-bottom: 0;
      }

      br {

      }
    }
  }
}

.last-call-notice {
  font-weight: bold;
  color: $mainRed;
}
