@import "../../../assets/scss/definitions";

.profile-tabs {
  .tab-content {
    padding: 20px;
  }
}

.profile-table {
  overflow-y: scroll;
  color: $mainBlack;

  .profile-table-row {
    display: flex;
    padding: 5px;

    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.header-row {
      font-weight: bold;
      font-size: 1.1em;
      margin-bottom: 20px;
      border-bottom: 1px solid $lightGrey;
    }

    > div {
      margin-right: 20px;
      min-width: 100px;
      flex: 1;

      &.lot-title {
        hyphens: auto;
      }
    }
  }
}
