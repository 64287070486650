@import "../../../assets/scss/definitions";

.request-attendance-permission-modal {
  .content {
    padding: 20px;
    background: $veryLightGold;
    margin: 20px 0;

  }
}
