.message-display {
  position: fixed;
  top: 0;
  z-index: 100000;

  .message {
    font-size: 15px;
    line-height: 1.6;
    text-align: left;
    color: #363636;
    padding: 15px;
  }
}
