@import "definitions";


img {
  max-width: 100%;
  height: auto;
}

body {
  color: $mainBlack;
  font-size: 15px;

  @include serifFonts;
}

h1 {
  font-size: 1.87em;
}

h2 {
  font-size: 1.333em;
}

