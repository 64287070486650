.react-datetime-picker, .react-date-picker {
  width: 100%;

  .react-datetime-picker__wrapper, .react-date-picker__wrapper {
    width: 100%;

    height: 45px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #e9eef8;
    padding-left: 37px;

    display: flex;
    align-items: center;
  }

  .react-datetime-picker__clock {
    z-index: 2;
    border-radius: 4px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
    border: solid 1px #e9eef8 !important;
    background-color: #ffffff !important;
  }

  .react-datetime-picker__calendar-button, .react-date-picker__calendar-button {
    position: absolute;
    left: 10px;
  }

  .react-datetime-picker__clear-button, .react-date-picker__clear-button {
    padding-right: 12px;
  }

  .react-datetime-picker__button, .react-date-picker__button {
    color: #cbd9f5 !important;

    &:hover {
      color: #6f7d9a !important;
    }
  }

  .react-datetime-picker__inputGroup, .react-date-picker__inputGroup {
    color: #363636;

    input, .react-datetime-picker__inputGroup__leadingZero {
      font-size: 15px;
      line-height: 1.2;
      color: #363636 !important;

      &::placeholder {
        color: #7c8cac;
      }
    }
  }
}
