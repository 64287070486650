@import "../../../assets/scss/definitions";

.tk-catalogue-listing {

  border-bottom: solid 1px #f0f4f8;

  &:first-child {
    border-top: solid 1px #f0f4f8;
  }

  padding: 40px 0;

  .tk-thumbnail {
    align-items: center;
    display: flex;

    a {

      .tk-image {

      }

      .image-gallery-lot-status-display {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        text-align: center;

        span {
          font-size: 22px;
          line-height: 1.2;
          border: 3px solid $mainRed;
          color: $mainRed;
          background: rgba(255,255,255,0.7);
          padding: 10px;
          font-weight: bold;
          max-width: 60%;
        }
      }
    }
  }

  .tk-content {

    padding: 0 10px;

    .tk-lot-number {
      margin-bottom: 10px;
    }

    .tk-lot-name {
      margin-bottom: 10px;

      h2 {
        margin-top: 0;
        margin-bottom: 10px;

        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        @include serifFonts;

        a {
          color: $mainBlack;
          text-decoration: none;
        }
      }
    }

    .tk-lot-description {
      font-size: 15px;
      @include serifFonts;
    }
  }

  .tk-lot-stats {

    padding-top: 2.5rem;
    @include sansFonts;
    color: $lightGrey;

    > div {
      margin-bottom: 10px;
    }

    .tk-lot-condition {

    }

    .tk-lot-bid-count {

    }

    .tk-lot-starting-bid {
      color: $mainBlack;
    }

    .tk-lot-link {

      a {
        color: $lightGold;

        .svg-inline--fa {
          margin-left: 10px;
        }
      }
    }
  }
}
