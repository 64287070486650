@import "../../../../assets/scss/definitions";

.auth-form-wrapper {
  max-width: 600px;


  .form-group {
    &.checkbox {

      label {
        width: 100%;
        font-weight: 400;
      }

      input[type='checkbox'] {
        width: auto;
        margin-right: 10px;
        height: auto;
        margin-top: 0.4em;
      }

      a, button.btn-link {
        color: $mainGold;

        &:hover {
          color: darken($mainGold, 20);
          cursor: pointer;
        }
      }
    }
  }


}

.registration-consent-modal .modal-content {
  padding: 40px;

  h2 {
    margin-bottom: 40px;
  }
}
